// src/Views/HomePage.js
import React from 'react';
import './HomePage.css';
import Header from './Header.js';
import Footer from './Footer.js';
import lonestarLogo from '../assets/images/lonestar-logo.png';
import evvyLogo from '../assets/images/evvy-logo.png';
import sigma3Logo from '../assets/images/sigma3-logo.png';
import txstLogo from '../assets/images/txst-logo.png';

const HomePage = () => {
    return (
        <div className="homepage">
            <Header />
            <main className="main-content">
                <section className="description">
                    <h2>About Me</h2>
                    <p>
                        Thomas Remmert is a dynamic and visionary entrepreneur whose career is distinguished by his innovative contributions to the fields of IoT solutions, cryptocurrency accessibility, and scientific research. A graduate of Texas State University with a deep-rooted passion for Physics and Mathematics, Thomas has not only earned accolades from the American Physics Society but has also made significant strides in mentoring the next generation of scientists. His creation of a global lightning geolocation system underscores his ability to merge theoretical knowledge with practical application, showcasing his commitment to solving complex problems through technology. As a co-founder and key executive in several tech ventures, Thomas is recognized for his strategic foresight, technical prowess, and dedication to making cutting-edge technologies accessible and impactful in everyday life.
                    </p>
                </section>
                <section className="journey">
                    <h2>The Journey</h2>
                    <p>
                        As a visionary entrepreneur and technology innovator, I have dedicated my career to bridging the gap between cutting-edge technologies and practical applications, transforming industries from IoT solutions to cryptocurrency accessibility. My passion lies in leveraging technology to create seamless, secure, and accessible experiences, driving change and fostering growth in every venture I undertake.
                    </p>
                </section>
                <section className="resume">
                    <div className="resume-item">
                        <div className="company-logo">
                            <img src={lonestarLogo} alt="LoneStar Tracking Logo" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                        </div>
                        <div className="resume-content">
                            <h3>LoneStar Tracking</h3>
                            <h4>Co-Founder and CTO – July 2019 – Present</h4>
                            <p style={{textAlign: 'left'}}>
                                At LoneStar Tracking, we specialize in innovative IoT solutions designed to enhance the tracking and monitoring of physical assets. Our expertise extends to managing fleets, analyzing driving behaviors, offering 360-degree real-time video streaming, and providing cost-effective sensors tailored for the agriculture and oil and gas sectors.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                In my role as co-founder and Chief Technology Officer, I've been instrumental in shaping the company's strategic direction and deploying our distinctive infrastructure. Our success is anchored in forging strategic global partnerships with carriers, leveraging diverse technologies like LPWAN, conventional cellular networks, VSAT, and Cube Satellite technology. This technological diversity enables us to offer unparalleled tracking and monitoring capabilities, especially in remote areas lacking conventional coverage.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                Our commitment to excellence is further underscored by our reliance on Amazon AWS for our entire infrastructure. This choice ensures a secure, swift, and scalable environment, enabling us to meet our customers' needs effectively. At LoneStar Tracking, we pride ourselves on creating a seamless experience for our users, pushing the boundaries of what's possible in the realm of IoT solutions.
                            </p>
                        </div>
                    </div>
                    <div className="resume-item">
                        <div className="company-logo">
                            <img src={evvyLogo} alt="Evvy Tech Logo" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                        </div>
                        <div className="resume-content">
                            <h3>Evvy Tech</h3>
                            <h4>Co-Founder and CCO – Nov 2019 – Jan 2024</h4>
                            <p style={{textAlign: 'left'}}>
                                Evvy Tech is committed to demystifying the crypto world for all. As a co-founder and Chief Compliance Officer, I lead our efforts to merge traditional finance with the emerging crypto landscape. Our network of Bitcoin ATMs and Crypto Terminals across Texas exemplifies our mission to make crypto transactions straightforward and widespread.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                Beyond merely facilitating transactions, we're driven to innovate and dismantle the barriers surrounding blockchain technology. Our team is developing state-of-the-art solutions to make blockchain accessible to everyone, aiming to transform it from a niche interest to a universal tool.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                Our focus is on delivering a seamless, secure, and accessible user experience. By harnessing advanced blockchain technology, we strive to provide easy-to-use, safe, and effective solutions. This commitment stems from our belief in blockchain's power to revolutionize financial interactions worldwide.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                At Evvy Tech, we're not just a business; we're at the forefront of a digital revolution, empowering people to confidently explore the crypto space. Our dedication to innovation and excellence positions us as leaders in facilitating widespread cryptocurrency adoption.
                            </p>
                        </div>
                    </div>
                    <div className="resume-item">
                        <div className="company-logo">
                            <img src={sigma3Logo} alt="Sigma3 Integrated Reservoir Solutions Logo" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                        </div>
                        <div className="resume-content">
                            <h3>Sigma3 Integrated Reservoir Solutions</h3>
                            <h4>Seismic Engineer / RTO Engineer – March 2010 July 2019</h4>
                            <p style={{textAlign: 'left'}}>
                                At SIGMA3 Integrated Reservoir Solutions, backed by Symphony Technology Group (STG), we excel in managing petroleum asset lifecycles for oil & gas companies. Our acquisition of five companies in the E&P space has forged a unified platform guiding engineers on optimal drilling and fracturing to enhance well and field performance.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                As Real-Time Operations Manager and part of the management team, I strategize and oversee microseismic acquisition and data processing. This includes collaborating with field crews, managing Amazon Web Services infrastructure, ensuring quality seismic records, and developing new hardware/software for fracture mapping. I also design and implement communication infrastructures and sensor networks for real-time remote analysis (SCADA), coordinating with telecom providers and managing connectivity solutions.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                My role demands excellence in fast-paced, high-stress environments, emphasizing clear communication and thorough documentation. I manage microseismic projects end-to-end, lead in fiber optic acoustic data acquisition (DAS) R&D, and contribute to technical sales and data processing oversight, ensuring SIGMA3's leadership in oil & gas asset management.
                            </p>
                        </div>
                    </div>
                    <div className="resume-item">
                        <div className="company-logo">
                            <img src={txstLogo} alt="Texas State University Logo" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                        </div>
                        <div className="resume-content">
                            <h3>Texas State University Center for Research and Commercialization</h3>
                            <h4>Engineer – May 2009 – May 2010</h4>
                            <p style={{textAlign: 'left'}}>
                                In addition to my academic achievements at Texas State University, I also contributed significantly to the Center for Research and Commercialization, where I collaborated with a talented team of software and hardware engineers. Together, we developed an innovative football location system, a project that merged my passion for physics and mathematics with practical technology solutions. This cutting-edge system, deployed and rigorously tested at the Texas State University stadium, showcased the potential of precision technology in sports, delivering real-time football location data with an impressive accuracy of about 3-5cm. This endeavor not only highlighted my ability to lead and innovate within interdisciplinary teams but also demonstrated my commitment to applying scientific principles to enhance real-world applications. Through this project, I contributed to advancing the integration of technology in sports, opening new avenues for analysis, strategy, and viewer engagement.
                            </p>
                        </div>
                    </div>
                    <div className="resume-item">
                        <div className="company-logo">
                            <img src={txstLogo} alt="Texas State University Logo" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                        </div>
                        <div className="resume-content">
                            <h3>Texas State University</h3>
                            <h4>B.S. in Physics earned in May 2010</h4>
                            <p style={{textAlign: 'left'}}>
                                During my tenure at Texas State University, studying Physics and Math, I delved deeply into these subjects, achieving recognition through several awards from the American Physics Society (APS). Beyond academic achievements, I engaged with the APS in a mentorship role, sharing insights and encouraging undergraduates to explore the vast possibilities within physics.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                A pivotal project I undertook was designing a global lightning geolocation system. This initiative wasn't merely an academic pursuit but a challenge that demanded innovation and interdisciplinary effort. It aimed to improve our comprehension of lightning activity, providing valuable data for weather prediction, climate studies, and environmental safety.
                            </p>
                            <p style={{textAlign: 'left'}}>
                                My college years were a blend of learning, innovation, and mentorship, laying a solid foundation for my future endeavors in technology and science.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default HomePage;