import React from 'react';
import './Header.css';
import thomasImage from '../assets/images/Thomas-Remmert.jpg';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="left-section">
          <span className="logo-text">{`{ Thomas Remmert. }`}</span>
          <div className="headings">
            <h2 className="heading">Entrepreneur</h2>
            <h2 className="heading">Engineer</h2>
            <h2 className="heading">Scientist
              
            </h2>
          </div>
        </div>
        <div className="right-section">
          <img 
            src={thomasImage} 
            alt="Thomas Remmert" 
            className="header-image" 
            style={{ maxWidth: '350px', height: 'auto', borderRadius: '10px' }} 
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
